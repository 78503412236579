import React, { useEffect, useRef, useState } from 'react';
import { TalkingHead } from "./modules/talkinghead.mjs";

const ENV = process.env.NODE_ENV;
const baseURL = ENV === 'development' ? 'http://localhost:3333' : 'https://mce.33web.me';

function ChatbotInterface() {
  const avatarRef = useRef(null);
  const headRef = useRef(null);
  const chatContainerRef = useRef(null);
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [talkingHeadLoaded, setTalkingHeadLoaded] = useState(false);

  useEffect(() => {
    const loadTalkingHead = async () => {
      try {

        if (avatarRef.current && !headRef.current) {
          headRef.current = new TalkingHead(avatarRef.current, {
            lipsyncModules: ["en"],
            ttsEndpoint: `${baseURL}/api/gtts/`,
            jwtGet: async () => {
              const response = await fetch('/api/get-jwt');
              const data = await response.json();
              return data.token;
            }
          });

          await headRef.current.showAvatar({
            url: 'https://models.readyplayer.me/6689dab0649e11cdd6fbee14.glb',
            body: 'F',
            avatarMood: 'neutral',
            ttsLang: "en-US",
            ttsVoice: "en-US-Standard-A",
            lipsyncLang: 'en',
            cameraView: "head"
          });

          console.log("Avatar loaded successfully");
          setTalkingHeadLoaded(true);
        }
      } catch (error) {
        console.error("Error initializing TalkingHead:", error);
      }
    };

    loadTalkingHead();
  }, []);

  const sendMessage = async () => {
    if (input.trim() === '') return;

    const newMessages = [
      ...messages,
      { text: input, sender: 'user' },
      { text: `Echo: ${input}`, sender: 'bot' }
    ];
    setMessages(newMessages);
    setInput('');

    // Make the avatar speak the bot's response
    if (headRef.current) {
      headRef.current.speakText(`Echo: ${input}`);
    }

    // Scroll to bottom of chat
    setTimeout(() => {
      if (chatContainerRef.current) {
        chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
      }
    }, 0);
  };


  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100vh', width: '100%', background: '#f0f0f0' }}>
      <div ref={avatarRef} style={{ height: '40%', width: '100%' }}>
        {!talkingHeadLoaded && <div>Loading avatar...</div>}
      </div>
      <div style={{ height: '60%', width: '100%', display: 'flex', flexDirection: 'column', padding: '20px', boxSizing: 'border-box' }}>
        <div ref={chatContainerRef} style={{ flex: 1, overflowY: 'auto', marginBottom: '20px', background: 'white', borderRadius: '10px', padding: '10px' }}>
          {messages.map((message, index) => (
            <div key={index} style={{ 
              marginBottom: '10px', 
              textAlign: message.sender === 'user' ? 'right' : 'left',
            }}>
              <div style={{
                background: message.sender === 'user' ? '#DCF8C6' : '#E0E0E0',
                padding: '8px 12px',
                borderRadius: '18px',
                display: 'inline-block',
                maxWidth: '70%'
              }}>
                <strong>{message.sender === 'user' ? 'You' : 'Bot'}: </strong>{message.text}
              </div>
            </div>
          ))}
        </div>
        <div style={{ display: 'flex' }}>
          <input 
            type="text" 
            value={input} 
            onChange={(e) => setInput(e.target.value)}
            onKeyPress={(e) => e.key === 'Enter' && sendMessage()}
            style={{ flex: 1, marginRight: '10px', padding: '10px', borderRadius: '20px', border: '1px solid #ccc' }}
            placeholder="Type your message..."
          />
          <button 
            onClick={sendMessage}
            style={{ padding: '10px 20px', borderRadius: '20px', border: 'none', background: '#4CAF50', color: 'white', cursor: 'pointer' }}
          >
            Send
          </button>
        </div>
      </div>
    </div>
  );
}

export default ChatbotInterface;